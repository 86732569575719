import { faPlus, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { Input, PrimaryButton } from 'component-library';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import { boatModelsListColumns, GetBoatModelsListData } from '../../helpers/BoatModelsListSetup';
import { GetBoatModels } from '../../services/BoatModelsService';
import { RootState } from '../../store/reducers/combine';
import useRequest from '../../utils/net/useRequest';
import PermissionGate, { ROLES } from '../../utils/PermissionGate';
import useWindowSize from '../../utils/UseWindowSize';
import Table from '../Table/Table';

function BoatModels() {
  const size = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams({ searchValue: '' });
  const navigate = useNavigate();
  const { currentOemTenantId, isHumphreeUser, currentOem } = useContext(AppContext);
  const [fetchCounter, setFetchCounter] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const oems = useSelector((state: any) => state.oems.data);
  const propulsionSystems = useSelector((state: RootState) => state.propulsionSystems.propulsionSystems);
  const hasError = useSelector((state: RootState) => state.boatModels.hasError);
  const [boatModels = [], isBoatModelsLoading, boatModelsGetsError] = useRequest(
    () => GetBoatModels(isOemSpecific()),
    [currentOemTenantId, fetchCounter]
  );

  function isOemSpecific() {
    if (currentOemTenantId === '12345678-1234-1234-1234-123456789000') {
      return false;
    }
    return true;
  }

  function goToBoatDetails(value: any, event: any) {
    if (event.ctrlKey || event.metaKey || event.which === 2) {
      window.open('models/' + boatModels.find((boatModel: any) => boatModel.id === value.boatModelId).id, '_blank');
    } else navigate(value.boatModelId);
  }

  useEffect(() => {
    const searchValueFromParams = searchParams.get('searchValue');
    setSearchValue(searchValueFromParams || '');
  }, [searchParams]);

  return (
    <div className='max-w-[2000px]'>
      <span className='prose-heading3 mb-14'>Boat models</span>
      {isBoatModelsLoading ? ( //How do we set isLoading correct?
        <div className='flex items-center flex-col gap-4 h-96 justify-center'>
          <span className='text-primary-400 prose-heading5'>Loading models...</span>
          <div className='w-12 h-12 border-l-2 border-primary-400 rounded-full animate-spin'></div>
        </div>
      ) : (
        <div>
          <div className='mt-14 border border-gray-10 p-4 flex justify-between items-center'>
            <div className='w-1/3 mb-1'>
              <Input
                placeholder='Search for boat models...'
                icon={faSearch}
                iconbefore
                value={searchValue}
                onChange={(value: string) => {
                  searchParams.set('searchValue', value);
                  setSearchValue(value);
                  searchParams.set('page', '1');
                  setSearchParams(searchParams);
                }}
              />
            </div>
            <PermissionGate roles={[ROLES.admin, ROLES.editor, ROLES.oemAdmin, ROLES.oemEditor]} rejected={undefined}>
              <PrimaryButton
                onClick={() => navigate('/createModel')}
                icon={faPlus}
                label='Add new boat model'
                size={window.innerWidth > 1500 ? 'small' : 'tiny'}
              />
            </PermissionGate>
          </div>
          {!hasError ? (
            <Table
              data={GetBoatModelsListData(boatModels, propulsionSystems, currentOem ? currentOem : oems, oems)}
              columns={boatModelsListColumns}
              onClick={(value: any, event?: any) => goToBoatDetails(value, event)}
              searchValue={searchValue}
              type='models'
              setColumnVisibility={{
                oemName: isHumphreeUser,
                status: size?.width > 1200,
                propulsion: size?.width > 1000,
              }}
            />
          ) : (
            <div className='w-full flex justify-between items-center p-4 px-8 bg-error-100 border-x border-gray-10'>
              <span className='text-white prose-paragraphBase italic'>Could not load models!</span>
              <span
                onClick={() => setFetchCounter(fetchCounter + 1)}
                className='text-white prose-paragraphBase italic cursor-pointer underline'
              >
                Try again
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default BoatModels;
